export const mediaArticles = [
  {
    id: 19,
    source_url: "https://prim.rbc.ru/prim/freenews/67b5214a9a7947c920a9247b",
    media_name: "РБК",
    screen_url: "rbk_prewiev.png",
    full_screen_url: "rbk_full_screen.png",
    logo_url: "rbc_logo.png",
    mobile_logo: "rbk_prewiev.png",
    date: "19.02.2025"
  },
  {
    id: 18,
    source_url: "https://riamo.ru/news/ekonomika/kachestvennye-izmenenija-ekspert-rasskazal-o-torgovyh-otnoshenijah-rf-i-kitaja/",
    media_name: "riamo.ru",
    screen_url: "riamo_preview.png",
    full_screen_url: "riamo_full_screen.png",
    logo_url: "riamo_logo.png",
    mobile_logo: "riamo_preview.png",
    date: "17.02.2025"
  },
  {
    id: 17,
    source_url: "https://fedpress.ru/article/3363826",
    media_name: "fedpress.ru",
    screen_url: "fd_mobile.png",
    full_screen_url: "fd_full_screen.png",
    logo_url: "fd.png",
    mobile_logo: "fd_mobile.png",
    date: "17.02.2025"
  },
  {
    id: 16,
    source_url: "https://vgudok.com/lenta/sozavisimye-otnosheniya-rossii-i-kitaya-kak-trend-mirovoy-logistiki-rastushchie-ceny-na",
    media_name: "vgudok.com",
    screen_url: "vgudok_mobile.png",
    full_screen_url: "vgudok_full_screen.png",
    logo_url: "vgudok_logo.svg",
    mobile_logo: "vgudok_mobile.png",
    date: "24.01.2025"
  },
  {
    id: 15,
    source_url: "https://vc.ru/transport/1680743-kak-vybrat-nadezhnogo-partnera-dlya-konteinernyh-perevozok",
    media_name: "vc.ru",
    screen_url: "vc.ru_2024_11_29.png",
    full_screen_url: "vc.ru_2024_11_29_full.png",
    logo_url: "vcru_logo.png",
    mobile_logo: "vc.ru_2024_11_29_mobile.jpg",
    date: "29.11.2024"
  },

  {
    id: 14,
    source_url: "https://pro.rbc.ru/demo/66fd74679a79471df15b0cf7?ysclid=m1t1gy1jp6858263998",
    media_name: "РБК",
    screen_url: "rbc_2024_11.png",
    full_screen_url: "rbc_2024_11_full.png",
    logo_url: "rbc_logo.png",
    mobile_logo: "rbc_2024_11_mobile.jpg",
    date: ""
  },

  {
    id: 13,
    source_url: "https://rg.ru/2024/10/22/reg-cfo/v-poiskah-obhoda.html",
    media_name: "Российская газета",
    screen_url: "rg.ru_2024_10_22.png",
    full_screen_url: "rg.ru_2024_10_22_full.png",
    logo_url: "rgru_logo.png",
    mobile_logo: "rg.ru_2024_10_22_mobile.jpg",
    date: "22.10.2024"
  },

  {
    id: 12,
    source_url: "https://biz360.ru/materials/logistika-budet-dorozhat-kak-transformiruetsya-rynok-konteynernykh-perevozok-v-rossii/",
    media_name: "biz360",
    screen_url: "logistics_screen.webp",
    full_screen_url: "logistics_full_screen.webp",
    logo_url: "logistics_logo.webp",
    mobile_logo: "logistics_mobile.webp",
    date: "17.06.2024"
  },
  
  {
    id: 11,
    source_url: "https://horizonevents.ru/interview/kak-segodnya-platit-v-kitaj-finansovaya-logistika-s-podnebesnoj/",
    media_name: "Горизонт событий",
    screen_url: "horizon_screen.png",
    full_screen_url: "horizonevents_full_screen.png",
    logo_url: "horizon_logo.png",
    mobile_logo: "horizon_mobile.png",
    date: "21.05.2024"
  },

  {
    id: 10,
    source_url: "https://vc.ru/u/2050823-inna-balanova/1070808-nazad-v-budushchee-it-platforma-vedexx-provela-pervyy-vebinar-i-opredelila-kurs-rynka-ved-v-2024-godu",
    media_name: "vc.ru",
    screen_url: "vcru_screen.png",
    full_screen_url: "vcru_full_screen.png",
    logo_url: "vcru_logo.png",
    mobile_logo: "vcru_mobile.png",
    date: "11.03.2024"
  },
  
  {
    id: 9,
    source_url: "https://new-retail.ru/business/upravlenie_riskami_v_tsepochke_postavok_effektivnyy_algoritm_v_novykh_usloviyakh/",
    media_name: "New Retail",
    screen_url: "new-retail_screen.png",
    full_screen_url: "new-retail_screen_full.png",
    logo_url: "new-retail_logo.webp",
    mobile_logo: "new-retail_mobile.png",
    date: "05.03.2024"
  },
  
  {
    id: 8,
    source_url: "https://rg.ru/2024/02/20/reg-cfo/intellekt-v-dejstvii.html",
    media_name: "Российская газета",
    screen_url: "rgru_screen.png",
    full_screen_url: "rgru_full_screen.png",
    logo_url: "rgru_logo.png",
    mobile_logo: "rgru_mobile.jpeg",
    date: "20.02.2024"
  },
  {
    id: 7,
    source_url: "https://abnews.ru/center/news/msk/2024/1/29/tuz-v-rukave-rossii-ekspert-kovganko-ne-stal-skryvat-vliyaniya-konfliktov-na-logistiku?ysclid=ls01xj1cy6691970627",
    media_name: "АБН24",
    screen_url: "abn24_screen.webp",
    full_screen_url: "abn24_screen_full.png",
    logo_url: "abn24_logo.png",
    mobile_logo: "abn24_mobile.webp",
    date: "29.01.2024"
  },
  {
    id: 6,
    source_url: "https://pro.rbc.ru/demo/657fc29f9a79478cfc713051",
    media_name: "РБК",
    screen_url: "rbc_screen.webp",
    full_screen_url: "rbc_screen_full.png",
    logo_url: "rbc_logo.png",
    mobile_logo: "rbc_mobile.webp",
    date: "29.12.2023"
  },
  {
    id: 3,
    source_url: "https://abnews.ru/news/2023/6/19/rynok-uslug-mezhdunarodnyh-kontejnernyh-perevozok-oczifrovali",
    media_name: "Агенство Бизнес Новостей",
    screen_url: "abn_screen.webp",
    full_screen_url: "abn_screen_full.png",
    logo_url: "abn_logo.png",
    mobile_logo: "abn_mobile.webp",
    date: "19.06.2023"
  },
  {
    id: 1,
    source_url: "https://spb.aif.ru/view/money/industry/4621265?key=e7886dd323d287891edfe33705740242",
    media_name: "Аргументы и факты",
    screen_url: "aif_screen.webp",
    full_screen_url: "aif_screen_full.png",
    logo_url: "aif_logo.svg",
    mobile_logo: "aif_mobile.webp",
    date: "19.06.2023"
  },
  {
    id: 5,
    source_url: "https://bfmspb.ru/novosti/portovaya-dostavka-v-odin-klik-v-peterburge-prezentovali-it-sistemu-dlya-uchastnikov-ved",
    media_name: "Бизнес ФМ",
    screen_url: "bfm_screen.webp",
    full_screen_url: "bfm_screen_full.webp",
    logo_url: "bfm_logo.png",
    mobile_logo: "bfm_mobile.webp",
    date: "19.06.2023"
  },
  {
    id: 4,
    source_url: "https://spb.mk.ru/social/2023/06/19/peterburgskaya-kompaniya-pobezhdaet-geopolitiku.html",
    media_name: "МКRU",
    screen_url: "mkru_screen.webp",
    full_screen_url: "mkru_screen_full.png",
    logo_url: "mkru_logo.jpg",
    mobile_logo: "mkru_mobile.webp",
    date: "19.06.2023"
  },
  {
    id: 2,
    source_url: "https://spb.dk.ru/news/237185540",
    media_name: "Деловой квартал",
    screen_url: "dk_screen.png",
    full_screen_url: "dk_screen_full.png",
    logo_url: "dk_logo.jpg",
    mobile_logo: "dk_mobile.webp",
    date: "19.06.2023"
  },
]
