<script>
import {bus} from "../../router/bus";

export default {
  name: "Article",
  data: () => ({
    article: [],
  }),
  
  mounted() {
    this.getArticle(this.$route.params.id)
  },
  
  methods: {
    getArticle(id) {
      const uri = this.$api + this.$methods.getArticleBodyJson.url
      const headers = {
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        }
      };
      const params = {
        id: id
      }
      this.$postapi(uri, params, headers)
      .then(data => {
        if (data.error === 0) {
          this.article.push(JSON.parse(data.message))
        } else {
          bus.$emit("show_notify", {
            color: "error",
            notifytext: data.message
          });
        }
      });
    },
  }
}
</script>

<template>
  <div class="article">
   <div class="article__title" v-if="$route.params.id == 8 || $route.params.id == 9 || $route.params.id == 10 || $route.params.id == 12"><h2 ></h2></div>
   <div class="article__title" v-else>{{article[0].title}}</div>
    <div class="article__content" v-html="article[0].content">
    </div>
<!--    <div class="article__date"> {{article[0].publish_date.slice(11,16)}}</div>-->
<!--    <div class="article__date"> {{article[0].publish_date.slice(0,10)}}</div>-->
  </div>
</template>

<style scoped lang="scss">
.article {
  width: 75%;
  margin: auto;
  padding: 5%;
  background: #FFFFFF;
  font-family: $font-family;

}

h1 {
  font-size: 50px !important;
}

.v-application .title {
  font-size: 20rem !important;
}

</style>
