<script>
import {bus} from "../../../router/bus";
import { formatNewsDate } from '@/utils/formatNewsDate';

export default {
  name: "Article",
  data: () => ({
    article: null,
  }),
  
  mounted() {
    this.getNews(this.$route.params.id)
  },

  computed: {
    formattedArticleDate(){
      return formatNewsDate(this.article.publish_date)
    }
  },
  
  methods: {
    getNews(id) {
      const url = this.$api + this.$methods.news.url + `/${id}`
      
      this.$getapi(url)
      .then(data => {
        this.article = data
        
      }).catch(err => {
        bus.$emit("show_notify", {
          color: "error",
          notifytext: err.message
        });
      });
    },
  }
}
</script>

<template>
  <div class="article">
    
    <div class="article__title">
      {{ article.title }}
    </div>
    
    <div class="article__text">
      {{ article.short_text }}
    </div>
    
    <br/>
    
    <div class="article__text" v-html="article.text">
    </div>
    
    <div class="article__date">
      {{ formattedArticleDate }}
    </div>
    
  </div>
</template>

<style scoped lang="scss">
.article {
  width: 70%;
  margin: auto;
  padding: 2%;
  background: #FFFFFF;
  
  @media (max-width: 600px) {
    width: 96%;
  }

  &__title {
    text-align: left;
    font-size: 44px !important;
    margin: 15px 0;
    font-weight: 600;
    
    @media (max-width: 600px) {
      font-size: 22px !important;
    }
  }

  &__text {
    margin: auto;
    font-size: 14pt;
    color: #212121;
    
    @media (max-width: 600px) {
      font-size: 12px;
    }
  }

  &__date {
    margin: 16px auto;
    font-size: 14pt;
    color: #92A1A6;
    
    @media (max-width: 600px) {
      font-size: 12px;
    }
  }
}

h1 {
  font-size: 50px !important;
}

.v-application .title {
  font-size: 20rem !important;
}

</style>
