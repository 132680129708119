<script>
import NewsCard from "./NewsCard";
import {bus} from "@/router/bus";

export default {
  name: 'NewsList',
  components: {
    NewsCard
  },
  
  data() {
    return {
      news: [],
      currentPage: 1,
      totalPages: 1
    }
  },

  mounted() {
    this.getNews()
  },
  
  methods: {
    getNews(page = 1) {
      const params = {
        'sort[date_creation]': 'desc',
        'page': page
      }

      this.$getapi(this.$api + this.$methods.news.url, { params })
      .then(data => {
        if(page === 1){
          this.news = data.data
        }else{
          this.news = [...this.news, ...data.data]
        }
        this.currentPage = page
        this.totalPages = data.meta.last_page
        
      }).catch(err => {
          bus.$emit("show_notify", {
          color: "error",
          notifytext: err.message
        });
      });
    },

    showMoreNews(){
      if (this.currentPage < this.totalPages) {
        this.getNews(this.currentPage + 1);
      }
    },
  }
}
</script>

<template>
  <div class="news-page">
    <div class="news-page__title">Vedexx Новости</div>
    <hr class="news-page__line"/>
    
    <div v-if="news.length > 0">
      <div
        class="news-page__cards"
        v-for="report in news"
        :key="report.id"
      >
        <NewsCard class="news-page__cards__card" :news="report"/>
      </div>
    </div>
    <button
      v-if="currentPage < totalPages"
      @click="showMoreNews"
      class="news-page__show-more"
    >
      Показать больше
    </button>
  </div>
</template>

<style scoped lang="scss">

.news-page {
  margin: 20px;
  display: flex;
  flex-direction: column;
  
  @media (max-width: 600px) {
    margin: 10px;
  }

  &__title {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 35px;
    color: #301E06;
  }
  
  &__line {
    margin: 8px 0 20px;
    color: black;
  }
  
  &__cards {
    width: 70%;
    margin: auto;
    padding-bottom: $size-12;
    background: #FFFFFF;
    box-shadow: 1.97853px 1.97853px 8.9034px rgba(0, 0, 0, 0.25);
    border-radius: 0 0 8px 8px;
    
    @media (max-width: 600px) {
      margin-top: 5px;
      width: 100%;
      border-radius: 8px 8px;
    }
  }

  &__show-more{
    @include font-description-semibold-0;
    margin-top: 24px;
    width: 100%;
    color: black;
    cursor: pointer;
    position: relative;

      &:after {
        position: absolute;
        content: '';
        background: url('../../../assets/img/icons/mdi/arrow-down-2-duotone.svg') no-repeat;
        background-size: 100%;
        width: 20px;
        height: 19px;
        top: 50%;
        transform: translateY(-50%);
    }
    
  }
}
</style>
