<template>
  <div class="document">
    <div class="document__content" v-html="article[0].content">
    </div>
  </div>
</template>

<script>
import {bus} from "../../router/bus";

export default {
  name: "Article",
  data: () => ({
    article: [],
  }),
  mounted() {
    this.getArticle(this.$route.params.id)
  },
  methods: {
    getArticle(id) {
      const uri = this.$api + this.$methods.getArticleBodyJson.url
      const headers = {
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        }
      };
      const params = {
        id: id
      }
      this.$postapi(uri, params, headers)
      .then(data => {
        if (data.error === 0) {
          this.article.push(JSON.parse(data.message))
        } else {
          bus.$emit("show_notify", {
            color: "error",
            notifytext: data.message
          });
        }
      });
    },
  }
}
</script>

<style scoped lang="scss">
.document {
  width: 75%;
  margin: auto;
  padding: 5%;
  background: #FFFFFF;
  z-index: 1;
  position: relative;
  font-family: $font-family;

  &__content {
    margin: auto;
    color: #212121;
  }
}
</style>
