<script>
import { formatNewsDate } from '@/utils/formatNewsDate';

export default {
  name: 'NewsCard',
  props: {
    news: {
      type: [Array, Object]
    }
  },

  computed: {
    formattedNewsDate(){
      return formatNewsDate(this.news.publish_date);
    }
  },
  
  methods: {
    openNewsPage(){
      let routeData = this.$router.resolve({ path: "/RatePage", query : { key: this.price.key_orig, on_date: this.on_date, cid: this.$_getsetting("client_id") } });
      window.open(routeData.href, '_blank');
    },
  }
}
</script>

<template>
  <router-link
    :to="'NewsPage/' + news.id"
  >
    <div
      class="news-card"
    >
    <div class="news-card__title">
      {{ news.title }}
    </div>
  
    <div class="news-card__text" v-html="news.short_text">
    </div>
  
    <div class="news-card__date">
      {{ formattedNewsDate }}
    </div>
    </div>
  </router-link>
</template>

<style lang="scss" scoped>
.news-card {
  background: #FFFFFF;
  border-bottom: 10px solid #72777A;
  border-radius: 8px;
  height: auto;
  padding: 30px 30px;
  width: 100%;
  transition-duration: 0.5s;
  cursor: pointer;
  position: relative;
  
  @media (max-width: 600px) {
    padding: 10px 10px 0;
    height: 140px;
    border-radius: 8px;
  }
  
  &:hover {
    //margin-top: 5px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.1), 0 20px 20px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px) scale(1.01);
  }
  
  &__title {
    @include font-body-bold-1;

    background: none;
    display: flex;
    align-items: center;
    color: black;
    letter-spacing: 0.25px;
    max-height: 60px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  
    @media (max-width: 600px) {
      font-size: 14px;
    }
  }
  
  &__text {
    @include font-body-2;

    display: flex;
    margin: 5px 0 10px;
    align-items: center;
    letter-spacing: 0.25px;
    color: #535353;
    max-height: 56px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  
    @media (max-width: 600px) {
      font-size: 14px;
      margin: 5px 0;
    }
  }
  
  &__date {
    @include font-subtitle-regular;
    
    letter-spacing: 0.4px;
    color: #000000;
  }
}

a {
  text-decoration: none;
}
</style>
