<script>
import {bus} from "../../router/bus";
import RatingForm from "./RatingForm";

export default {
  name: "RatingPage",
  
  components: {
    RatingForm
  },
  
  data: () => ({
    rateInfo: [],
    sent: false,
    rated: false,
  }),
  
  mounted() {
    this.getOrderMarksTemplate()
  
    bus.$on("set_mark", (data) => {
      this.setOrderMark(data)
    });
  },
  
  methods:{
    getOrderMarksTemplate(){
      const uri = this.$api + this.$methods.getOrderMarksTemplate.url
      const token = this.$_getsetting('token')
      const headers = {
        headers: {
          "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }
      };

      const params = {
        ord_id: this.$route.query.ord_id,
      }
      this.$postapi(uri, params, headers)
      .then(data => {
        if (data.error == 0){
          this.rateInfo = JSON.parse(data.message)
          this.isRated()
        } else {
          bus.$emit("show_notify", {
            color: "error",
            notifytext: data.message
          });
        }
      });
    },
    
    isRated() {
      if (this.rateInfo.marks.some(mark => mark.mark_value === 1) === true)
        this.rated = true
    },
    
    setOrderMark(data) {
      const uri = this.$api + this.$methods.setOrderMark.url
      const token = this.$_getsetting('token')
      const headers = {
        headers: {
          "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }
      };

      const params = {
        description: data.description,
        ord_id: this.$route.query.ord_id,
        mark_value: data.mark_value,
        marks: data.marks
      }
      this.$postapi(uri, params, headers)
      .then(data => {
        if (data.error === 0) {
          bus.$emit("show_notify", {
            color: "success",
            notifytext: "Спасибо, ваша оценка отправлена!"
          });
          this.sent = true
          setTimeout(() => {
            this.$router.push("/")
          }, 900);
        } else {
          bus.$emit("show_notify", {
            color: "error",
            notifytext: data.message
          });
        }
      });
    }
  }
}
</script>

<template>
  <div class="info-block">
<!--    <div class="info-block_title"></div>-->
    <v-card
      v-if="rated === true"
      class="elevation-16 mx-auto pt-16 mb-16 text-center"
      width="700"
      min-height="200"
    >
      <div class="text-center pt-4 text-h5">Поставка № {{rateInfo.ord_num}} уже была оценена!</div>
    </v-card>
    <RatingForm
      v-if="!sent && !rated"
      :fields="rateInfo.marks"
      :rateInfo="rateInfo"
    />
  </div>
</template>

<style scoped>
.info-block {
  width: 100%;
  font-family: 'Montserrat', sans-serif;
  margin: 10vh auto;
  text-align: center;
  letter-spacing: 0.01em;
  color: #FFFFFF;
}

.info-block_title {
  font-weight: 800;
  font-size: 40px;
  line-height: 60px;
}

.info-block_text {
  font-weight: 500;
  font-size: 22px;
  line-height: 33px;
}
</style>
