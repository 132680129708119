<script>
import {bus} from "../../router/bus";

export default {
  name: "CloseSubscription",
  
  mounted() {
    this.setActiveThreeBestRateParams(this.$route.query.id)
  },
  
  methods: {
    setActiveThreeBestRateParams(id) {
      const uri = this.$api + '/api/v1/signout'
      const token = this.$_getsetting('token')
      const headers = {
          headers: {
              "Accept": "application/json",
              "Content-Type": "application/json",
              "Authorization": `Bearer ${token}`,
          }
      };

      const params = {
        id: id,
        is_active: 0,
      }
      this.$postapi(uri, params, headers)
      .then((data) => {
        if (data.error != 0) {
          bus.$emit("show_notify", {
            color: "error",
            notifytext: data.message,
          });
        }
      });
    },
  }
}
</script>

<template>
  <div class="info-block">
    <div class="info-block_title">Ваша подписка отменена!</div>
  </div>
</template>

<style scoped>
.info-block {
  width: 100%;
  font-family: 'Montserrat', sans-serif;
  margin: 30vh auto;
  text-align: center;
  letter-spacing: 0.01em;
  color: #FFFFFF;
}

.info-block_title {
  font-weight: 800;
  font-size: 40px;
  line-height: 60px;
}
</style>
